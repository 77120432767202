const themeGen = theme => ({ // eslint-disable-line
  roundIcon: false,
  noBorderOnCountField: false,
  fixedCounterWidth: '64px',
  fixedCounterHeight: '32px',
  panelTopMargin: '8px',
  panelBottomMargin: '6px',
  panelWidth: '',
  panelWidthForPopup: '40%',
  panelWidthIpad: '17%',
  panelWidthMobile: '35%',
  panelWidthIpadForPopup: '50%',
  fixedIconButtonPadding: '5px',
  fontSizeCounterField: '20px',
  panelLeftPadding: '20px',
  fixedIconButtonHeight: '32px',
  fixedIconButtonWidth: '32px',
  hideInMobile: true,
  color: 'white',
  borderColor: '#000000',
  backgroundColor: '#000000',
  onHoverColor: 'white',
  onHoverBackgroundColor: '#525252',
  readOnly: false,
});

export default themeGen;

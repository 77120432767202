const palette = {
    inkclubPink: '#e8008c',
    inkclubPinkShade: '#b9006f',
    inkclubPinkLight: '#e31e87',
    inkclubYellow: '#fdd200',
    inkclubYellowShade: '#fdd52c',
    inkclubLightBlue: '#d2eef9',
    inkclubLightPeer: '#90c9e0',
    inkclubBlue: '#00b0ed',
    inkclubBlueShade: '#009cd7',
    inclubOrange: '#e93e30',
    inkclubOrangeLight: '#f05b49',
    blackGradient1: '#111',
    blackGradient2: '#222',
    blackGradient3: '#333',
    blackGradient4: '#525252',
    blackGradient6: '#666',
    blackGradient9: '#999',
    blackGradientb: '#b2b2b2',
    gray: '#cccecf',
    grayLight: '#e7e7e7',
    offWhite: '#f4f4f4',
    inkySkyBlueShadeLight: '#deedf9',
};
const theme = {
    siteId: 'inkclub',
    palette,
    radius: '0px',
    col: {
        primary: {
            alt: 'white',
            fg: palette.inkclubPink,
            peer: palette.inkclubPinkShade,
        },
        secondary: {
            alt: 'white',
            fg: '#000000',
            peer: palette.blackGradient4,
        },
        tertiary: {
            alt: 'white',
            fg: '#000000',
            peer: palette.blackGradient4,
        },
        panel: {
            alt: '#343434',
            fg: '#ebebeb',
            peer: '#d4d4d4',
        },
        info: {
            alt: 'white',
            fg: '#6376ba',
            peer: '#5367aa',
        },
        error: {
            alt: '#f15c4a',
            fg: '#ffe5e6',
            peer: 'white',
        },
        pale: {
            alt: '#000',
            fg: 'white',
            peer: '#c2c2c2',
        },
        // Test commit, adding a comment
        splash: {
            alt: 'black',
            fg: palette.inkclubLightBlue,
            // peer: palette.inkclubLightBluePeer,
        },
        puff: {
            alt: 'white',
            fg: palette.inkclubBlue,
            peer: palette.inkclubBlueShade,
        },
        vars: {
            priceDiscountActive: palette.inkclubPink,
            bodyBg: palette.inkclubYellow,
            priceDiscountOverruled: '#333',
            text: '#000000',
        },
    },
    skipColorClasses: true,
    font: {
        size: {
            /** @option {string} font.size.x4 */
            x4: '32px',
            /** @option {string} font.size.x3 */
            x3: '24px',
            /** @option {string} font.size.x2 */
            x2: '20px',
            /** @option {string} font.size.x1 */
            x1: '18px',
            /** This is the default font size
             * @option {string} font.size.m */
            m: '15.6px',
            /** @option {string} font.size.s0 */
            s1: '14px',
            /** @option {string} font.size.s1 */
            s2: '12px',
            /** @option {string} font.size.s2 */
            s3: '10px',
        },
        family: 'Arial, Helvetica, sans-serif',
    },
    conf: {
        showPDPLink: true,
        showBreadCrumbs: true,
        emvWarrantyImage: '/images/icon-Garanti-small.png',
        promotionImagePath: '/_ui/dist/theme-inkclub/images/icons/bullhorn_ebk_inkclub.svg',
        contactUsUrl: '/contact_us',
        showCountrySelector: true,
    },
};
export default theme;

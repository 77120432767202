// eslint-disable-next-line no-unused-vars
const themeGen = (theme) => {
  const res = {
    conf: {
      iconInAddToCartButton: false,
    },
  };
  return res;
};

export default themeGen;

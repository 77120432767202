const themeGen = theme => { // eslint-disable-line
  const res = {
    conf: {
      showOneKronaSticker: true,
    },
  };
  return res;
};

export default themeGen;

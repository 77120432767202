const defaultSticker = '1krStickerFi.png';
// eslint-disable-next-line no-unused-vars
const themeGen = (theme) => {
  const res = {
    enabled: true,
    oneKrSticker: {
      rootPath: 'images/icons/',
      sticker: {
        se: '1krSticker.png',
        at: defaultSticker,
        ch: '1krSticker_chf.png',
        nl: '1krSticker_pre_euro.png',
        no: '1krStickerDkNo.png',
        fr: defaultSticker,
        de: defaultSticker,
        dk: '1krStickerDkNo.png',
        be: defaultSticker,
        fi: defaultSticker,
        default: '1krSticker.png',
      },
    },
  };
  return res;
};

export default themeGen;

const defaultSticker = '1krStickerFi.png';

const themeGen = theme => ({ // eslint-disable-line
  panelWidth: ' md:w-3/4 lg:w-full',
  panelPaddings: 'p-4',
  panelMargins: ' mt-4 mx-2 md:mx-4',
  panelFlexClasses: ' flex-col lg:flex-row',
  iconHeight: '100%',
  innerDivJustification: ' justify-end',
  priceTextSize: 'text-x3',
  iconColorOnHover: '#525252',
  showOneKronaStickerInOneBuyPanel: true,
  showCartIcon: false,
  oneKrSticker: {
    rootPath: 'images/icons/',
    sticker: {
      se: '1krSticker.png',
      at: defaultSticker,
      ch: '1krSticker_chf.png',
      nl: '1krSticker_pre_euro.png',
      no: '1krStickerDkNo.png',
      fr: defaultSticker,
      de: defaultSticker,
      dk: '1krStickerDkNo.png',
      be: defaultSticker,
      fi: defaultSticker,
      default: '1krSticker.png',
    },
  },
});

export default themeGen;

const themeGen = theme => ({  // eslint-disable-line
  widthSecureEcommerceAndSatisfiedCustomerNavNode: '30%',
  footerBackgroundColor: '#343434',
  footerForegroundColor: 'white',
  conf: {
    showNewsletterSection: true,
  },
});

export default themeGen;

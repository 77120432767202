const themeGen = theme => ({ // eslint-disable-line
  applyPaddingSubscriptionPanel: true,
  productDetailsSeparator: '&emsp;',
  productDetailsBoldness: true,
  // maxHeightProductImageSection: '310px',
  borderSpecificationTable: false,
  gapSeparator: false,
  showEnvironmentalCertificates: false,
  showCartIcon: false,
  isHPLeagalSpecialCase: true,
});

export default themeGen;

// eslint-disable-next-line no-unused-vars
const themeGen = (theme) => {
  const res = {
    categories: ['t1'],
    defaultTypeCode: 't1',
    consumerSectionTextColor: 'fg-secondary',
    bgColor: 'pale',
    tagTextColor: theme.col.secondary.alt,
    tagBgColor: theme.col.secondary.fg,
    tagTextColorOnHover: theme.col.secondary.alt,
    applyBackgroundToStandAloneComponent: true,
    alternateDustbag: true,
  };
  return res;
};

export default themeGen;

// eslint-disable-next-line no-unused-vars
const themeGen = (theme) => {
  const res = {
    cockpitColor: 'panel-inverted',
    panelColor: 'pale',

    maxNumberOfModelsDesktop: 3,
  };
  return res;
};

export default themeGen;
